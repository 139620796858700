
import Vue from 'vue';
export default Vue.extend({
    name: 'crosswise',
    components: {},
    data() {
        return {
            loading:true,
            isPrimary:true,
            title:'留言寄语',
            isZoomed:false,
            isPublish:false,
            events: [],
            details:{},
            ruleForm: {
                sign_name: '',
                return_message: '',
                server:'1',
            },
            rules: {
                sign_name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                return_message: [
                    { required: true, message: '请输入留言', trigger: 'blur' }
                ]
            },
            params:{ server:'1',page:1,limit:9},
            total:0,
            onReachFlag:true
          }
    },
    created() {
        this.postUserInfo();
    },
    mounted() {
        const scrollingDiv = this.$refs.scrollingDiv as HTMLDivElement;
        const scrollHeight = scrollingDiv.scrollTop;
        scrollingDiv.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        openimg(event) {
            this.details = event
            this.isZoomed = !this.isZoomed
        },
        publish(){
            this.isPublish = !this.isPublish
        },
        goBack(){
           this.$router.replace({path:'/home'});
        },
        async postUserInfo() {
            try {
                const result = await this.$api.postMessage(this.params);
                this.events = [...this.events, ...result.data.list];
                this.total =  result.data.total
                this.loading = false
                this.isPrimary = false
            } catch (error) {
                console.log('error', error)
            }
        },
        async submitForm(formName) {
            try {
                const isValid = await new Promise(resolve => {
                    this.$refs[formName].validate((valid) => {
                        resolve(valid);
                    });
                });

                if (isValid) {
                    const result = await this.$api.postSubmit(this.ruleForm);
                    if (result.code == 1){
                        this.$message({
                            message: '恭喜你，这是一条成功消息',
                            type: 'success'
                        });
                        this.isPublish = false
                        this.ruleForm.sign_name = ''
                        this.ruleForm.return_message = ''
                        this.postUserInfo();
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                return false;
            }
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        handleScroll() {
            const scrollingDiv = this.$refs.scrollingDiv as HTMLDivElement;
            const scrollHeight = scrollingDiv.scrollTop;
            const pageHeight = this.params.page * 338
            // console.log(scrollHeight,pageHeight)
            if (scrollHeight > pageHeight){
                // 显示底部提示
                const limitlen = this.total / this.params.limit
                // 判断是否有数据
                if ( this.params.page >= limitlen){
                    this.onReachFlag = false
                    return;
                }else {
                    // console.log("还有数据")
                    this.onReachFlag = true
                }
                this.params.page ++
                this.postUserInfo()
            }
        },
    },
    beforeDestroy() {
        // 移除滚动事件监听，以避免内存泄漏
        window.removeEventListener('scroll', this.handleScroll);
    }

});
